
body {
  margin: 0;
  font-family: 'Calibri', sans-serif;
}

#root {
  margin: 0;
}

.container {
  position: relative;
  height: 100vh;
}

.conways {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.3;
}

.pannel {
  background-color: #003d99;
  border-radius: 20px;
  margin: 20px;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.9);
}

.personal-statement {
  display: block;
  padding: 10px;
  max-width: 600px;
  height: min-content;
  color: #ddd;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.9;
}

.personal-statement h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.personal-statement p {
  font-size: 1.5rem;
  margin-bottom: 10px;
  line-height: 1.6;
}

.conways-hint {
  background-color: #c910b0;
  opacity: 0.98;
  bottom: -50px;
  right: 0px;
  left: auto;
  top: auto;
  max-width: 500px;
}
.conways-hint a{
  color: #fcf3fb;
}
